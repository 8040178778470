$(function() {

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });
    function checkNetworkStatus() {
        if (navigator.onLine) {
            $.ajaxSetup({
                async: true,
                cache: false,
                context: $("#status"),
                dataType: "json",
                error: function (req, status, ex) {
                    showNetworkStatus(false);
                },
                success: function (data, status, req) {
                    showNetworkStatus(true);
                },
                timeout: 5000,
                type: "GET",
                url: "/fr/ping"
            });
            $.ajax();
        } else {
            showNetworkStatus(false);
        }
    }

    var currentlyOnline = null;
    function showNetworkStatus(online) {
        if (online != currentlyOnline) {
            if (online) {
                $('body').addClass('online').removeClass('offline');
            } else {
                $('body').addClass('offline').removeClass('online')
            }
            currentlyOnline = online;
        }
    }

    $(document.body).bind("online", checkNetworkStatus);
    $(document.body).bind("offline", checkNetworkStatus);
    checkNetworkStatus();

    $('.menu-closer').click(function (e) {
        e.preventDefault();
        $('body').removeClass('menu-open');
    });

    $('.menu-opener').click(function (e) {
        e.preventDefault();
        $('body').addClass('menu-open');
    });

    $('.tournee-opener').click(function (e) {
        if ($(this).hasClass('waiting')) {
            return;
        }
        e.preventDefault();
        $('body').toggleClass('tournee-open');
    });

    $('.menu-overlay').click(function (e) {
        e.preventDefault();
        $('body').removeClass('menu-open').removeClass('tournee-open');
    })

    $('[data-toggle="popover"]').popover();

    $('.go-plus').click(function(e) {
        var handler = StripeCheckout.configure({
            key: $('body').data('stripe'),
            locale: 'CAD',
            allowRememberMe: false,
            token: function(token) {
                $.ajax({
                    type: "POST",
                    url: '/fr/upgrade',
                    data: {
                        token: token.id
                    },
                    success: function(d) {
                        if (d.success) {
                            window.location.href = d.route;
                        }
                    }
                });
            }
        });

        handler.open({
            amount:      1000,
            name:        'GrainYLD',
            description: 'GrainYLD+',
            email: $('body').data('email')
        });
    });

    $('.main-container').css('bottom', $('footer').outerHeight() + 'px');

    retinajs();

});